footer {
  border-top: 4px solid #444;
  background: #000e;
  padding: 32px 0 112px;
}

footer > * {
  color: #f2f5deaa !important;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: larger;
}
.contact {
  font-style: normal;
}

.footerWrapper small {
  opacity: 0.6;
}

.footerCol {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 20px;
}

.footerRow {
  display: flex;
  align-items: center;
  margin: 16px 0 0;
}

.footerLogo {
  padding: 24px 24px 0;
  height: 172px;
  width: min-content;
  align-self: center;
}

.footerBottomRow {
  margin: 48px 24px 0;
  font-size: small;
  opacity: 0.5;
  text-align: center;
}

.separator {
  padding: 0 6px;
  opacity: 0.5;
}

@media screen and (min-width: 768px) {
  footer {
    padding: 56px 0 32px;
  }

  .footerWrapper {
    flex-direction: row;
    margin: 0 auto;
    max-width: 1488px;
  }

  .footerLogo {
    padding: 0 64px 24px;
    align-self: flex-end;
  }

  .footerBottomRow {
    text-align: right;
    margin-top: 0;
  }
}
