html,
body {
  margin: 0;
  padding: 0;
  image-rendering: -webkit-optimize-contrast;
  -webkit-font-smoothing: antialiased;
  width: 100vw;
}

body {
  background: #f2f5de;
  overflow-x: hidden;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Poppins;
}

main {
  margin: 0 auto;
  width: 100%;
  min-height: 1200px;
}

a,
a:visited {
  color: inherit;
  display: inline-block;
}

a:hover {
  text-decoration: none;
}

a.link,
a.link:visited {
  display: inline-block;
  color: #9fb50e;
}

a.inverted {
  text-decoration: none;
}

a.inverted:hover {
  text-decoration: underline;
}
