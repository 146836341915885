header {
  background-image: radial-gradient(
      ellipse at center,
      #0008 0%,
      #0006 50%,
      transparent 100%
    ),
    url("../images/food.webp");
  background-position: center center;
  background-size: cover;
  height: 61vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoLink {
  display: block;
  padding: 16px;
  scale: 0.5;
}

nav {
  bottom: 0;
  position: fixed;
  z-index: 1000;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 4px 0;
  width: 100dvw;
}

.listWrapper {
  max-width: 1600px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.navigation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style: none;
}

.navigation li {
  flex: 1;
}

.navigation li a {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  margin: 0 4px;
  padding: 0 4px;
  height: 48px;
  font-size: 14px;
  line-height: 20px;
  color: #420;
  text-decoration: none;
  transition: scale 120ms ease-in-out, background-color 120ms ease-in-out;
  border-radius: 300px;
  text-shadow: 0 1px 8px #fff;
}

.navigation li a.active,
.navigation li a:hover {
  scale: 1.02;
  background-color: #ffd780;
}

.navigation li a:hover:not(:active) {
  scale: 1.03;
  background-color: #ffd780bb;
}

@media screen and (min-width: 768px) {
  .logoLink {
    scale: 1;
    transition: scale 240ms ease-in-out;
    border-radius: 64px;
  }

  .logoLink:hover {
    scale: 1.1;
  }

  .logoLink:hover:not(:active) {
    scale: 1.12;
  }

  nav {
    top: 0;
    position: sticky;
  }

  .nav {
    border-bottom: solid 3px #fff;
    border-top: solid 3px #fff;
  }

  .navigation li a {
    margin: 0 8px;
    padding: 0 16px;
    height: 56px;
    font-size: 28px;
    line-height: 32px;
  }
}
