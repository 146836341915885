article {
  width: 100%;
  padding: 40px 20px 64px;
}

.articleImage {
  display: flex;
  width: 100%;
  max-width: min(100%, 640px);
  height: 640px;
  object-fit: cover;
  object-position: 50% 30%;
}

.articleImage__mobile {
  margin: 96px -20px 0;
  width: 100dvw;
  max-width: 100dvw;
  height: 320px;
}

aside {
  display: none;
}

h2 small,
h3 small {
  font-size: 24px;
  line-height: 64px;
  letter-spacing: 0.04em;
  opacity: 0.5;
}

.row {
  display: flex;
  justify-content: center;
  position: relative;
}

h1 {
  font-size: 56px;
  line-height: 64px;
  margin: 0 0 64px;
  font-weight: 600;
  letter-spacing: -0.03em;
}

h2 {
  margin: 96px 0 0;
  font-size: 40px;
  line-height: 48px;
  font-weight: 300;
  letter-spacing: -0.04em;
}

h2:first-child {
  margin: 0;
}

h3 {
  margin: 48px 0 0;
  font-size: 28px;
  line-height: 36px;
}

h4 {
  font-size: 28px;
  line-height: 36px;
  font-variant: all-small-caps;
  letter-spacing: 0.04em;
  opacity: 0.5;
}

p, .list li {
  margin: 24px 0 0;
  font-size: 20px;
  line-height: 32px;
}

.list li {
  list-style-position: inside;
  font-weight: bold;
  text-indent: -1em;
  padding-left: 1em;
}

.list li + p {
  margin-top: 2px;
}

.listCondensed li {
  margin-top: 4px;
}

.box {
  width: min(100dvw, 640px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 24px;
  gap: 32px 0;
}

.boxTower {
  display: grid;
  row-gap: 32px;
}

.dim {
  opacity: 0.5;
}

.imageLink:hover {
  filter: brightness(0.9);
}

@media screen and (min-width: 768px) {
  article {
    flex: 3 1;
    padding: 64px;
    width: calc(67dvw - 72px);
    max-width: 960px;
  }

  .articleImage__mobile {
    display: none;
  }

  .desktopHidden {
    display: none;
  }

  aside {
    flex: 1 1;
    overflow: hidden;
    display: grid;
    row-gap: 32px;
    max-width: 640px;
    margin: 32px 0;
  }

  h2 {
    max-width: 80%;
  }

  .box {
    height: 640px;
    padding: 0 32px 0 64px;
    gap: 48px 0;
  }
}

@media screen and (min-width: 1608px) {
  .articleImage {
    border-radius: 12px;
  }
}