.lead {
  max-width: 1000px;
  align-self: center;
  margin-top: 56px;
}

.lead-none {
  display: none;
}

.leadText {
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.02em;
  margin: 0;
  text-align: center;
  font-style: italic;
  padding: 0 0 32px;
}

.name {
  font-size: 20px;
  line-height: 24px;
  opacity: 0.9;
}

.jobTitle {
  font-size: 16px;
  opacity: 0.6;
}

.personal {
  display: flex;
  flex: 0 0 fit-content;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.info {
  text-align: right;
  margin: 0 16px 0 0;
  display: flex;
  flex-direction: column;
}

.avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: #777;
}

@media screen and (min-width: 768px) {
  .lead {
    padding: 32px 64px 32px;
  }
}
