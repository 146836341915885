html, body {
  image-rendering: -webkit-optimize-contrast;
  -webkit-font-smoothing: antialiased;
  width: 100vw;
  margin: 0;
  padding: 0;
}

body {
  background: #f2f5de;
  overflow-x: hidden;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Poppins;
}

main {
  width: 100%;
  min-height: 1200px;
  margin: 0 auto;
}

a, a:visited {
  color: inherit;
  display: inline-block;
}

a:hover {
  text-decoration: none;
}

a.link, a.link:visited {
  color: #9fb50e;
  display: inline-block;
}

a.inverted {
  text-decoration: none;
}

a.inverted:hover {
  text-decoration: underline;
}

header {
  background-image: radial-gradient(#0008 0%, #0006 50%, #0000 100%), url("food.3f02139a.webp");
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  height: 61vh;
  display: flex;
}

.logoLink {
  padding: 16px;
  display: block;
  scale: .5;
}

nav {
  z-index: 1000;
  position: fixed;
  bottom: 0;
}

.nav {
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  padding: 4px 0;
  display: flex;
}

.listWrapper {
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  display: flex;
}

.navigation {
  justify-content: space-between;
  width: 100%;
  list-style: none;
  display: flex;
}

.navigation li {
  flex: 1;
}

.navigation li a {
  text-align: center;
  color: #420;
  text-shadow: 0 1px 8px #fff;
  border-radius: 300px;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin: 0 4px;
  padding: 0 4px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  transition: scale .12s ease-in-out, background-color .12s ease-in-out;
  display: flex;
}

.navigation li a.active, .navigation li a:hover {
  background-color: #ffd780;
  scale: 1.02;
}

.navigation li a:hover:not(:active) {
  background-color: #ffd780bb;
  scale: 1.03;
}

@media screen and (width >= 768px) {
  .logoLink {
    border-radius: 64px;
    transition: scale .24s ease-in-out;
    scale: 1;
  }

  .logoLink:hover {
    scale: 1.1;
  }

  .logoLink:hover:not(:active) {
    scale: 1.12;
  }

  nav {
    position: sticky;
    top: 0;
  }

  .nav {
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
  }

  .navigation li a {
    height: 56px;
    margin: 0 8px;
    padding: 0 16px;
    font-size: 28px;
    line-height: 32px;
  }
}

footer {
  background: #000e;
  border-top: 4px solid #444;
  padding: 32px 0 112px;
}

footer > * {
  color: #f2f5deaa !important;
}

.footerWrapper {
  flex-direction: column;
  justify-content: center;
  font-size: larger;
  display: flex;
}

.contact {
  font-style: normal;
}

.footerWrapper small {
  opacity: .6;
}

.footerCol {
  flex-direction: column;
  flex: 1;
  padding: 24px 20px;
  display: flex;
}

.footerRow {
  align-items: center;
  margin: 16px 0 0;
  display: flex;
}

.footerLogo {
  align-self: center;
  width: min-content;
  height: 172px;
  padding: 24px 24px 0;
}

.footerBottomRow {
  opacity: .5;
  text-align: center;
  margin: 48px 24px 0;
  font-size: small;
}

.separator {
  opacity: .5;
  padding: 0 6px;
}

@media screen and (width >= 768px) {
  footer {
    padding: 56px 0 32px;
  }

  .footerWrapper {
    flex-direction: row;
    max-width: 1488px;
    margin: 0 auto;
  }

  .footerLogo {
    align-self: flex-end;
    padding: 0 64px 24px;
  }

  .footerBottomRow {
    text-align: right;
    margin-top: 0;
  }
}

.lead {
  align-self: center;
  max-width: 1000px;
  margin-top: 56px;
}

.lead-none {
  display: none;
}

.leadText {
  letter-spacing: .02em;
  text-align: center;
  margin: 0;
  padding: 0 0 32px;
  font-size: 24px;
  font-style: italic;
  line-height: 40px;
}

.name {
  opacity: .9;
  font-size: 20px;
  line-height: 24px;
}

.jobTitle {
  opacity: .6;
  font-size: 16px;
}

.personal {
  flex: 0 0 fit-content;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
}

.info {
  text-align: right;
  flex-direction: column;
  margin: 0 16px 0 0;
  display: flex;
}

.avatar {
  background: #777;
  border-radius: 50%;
  width: 64px;
  height: 64px;
}

@media screen and (width >= 768px) {
  .lead {
    padding: 32px 64px;
  }
}

article {
  width: 100%;
  padding: 40px 20px 64px;
}

.articleImage {
  object-fit: cover;
  object-position: 50% 30%;
  width: 100%;
  max-width: min(100%, 640px);
  height: 640px;
  display: flex;
}

.articleImage__mobile {
  width: 100dvw;
  max-width: 100dvw;
  height: 320px;
  margin: 96px -20px 0;
}

aside {
  display: none;
}

h2 small, h3 small {
  letter-spacing: .04em;
  opacity: .5;
  font-size: 24px;
  line-height: 64px;
}

.row {
  justify-content: center;
  display: flex;
  position: relative;
}

h1 {
  letter-spacing: -.03em;
  margin: 0 0 64px;
  font-size: 56px;
  font-weight: 600;
  line-height: 64px;
}

h2 {
  letter-spacing: -.04em;
  margin: 96px 0 0;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
}

h2:first-child {
  margin: 0;
}

h3 {
  margin: 48px 0 0;
  font-size: 28px;
  line-height: 36px;
}

h4 {
  font-variant: all-small-caps;
  letter-spacing: .04em;
  opacity: .5;
  font-size: 28px;
  line-height: 36px;
}

p, .list li {
  margin: 24px 0 0;
  font-size: 20px;
  line-height: 32px;
}

.list li {
  text-indent: -1em;
  padding-left: 1em;
  font-weight: bold;
  list-style-position: inside;
}

.list li + p {
  margin-top: 2px;
}

.listCondensed li {
  margin-top: 4px;
}

.box {
  flex-direction: column;
  justify-content: center;
  gap: 32px 0;
  width: min(100dvw, 640px);
  padding: 64px 24px;
  display: flex;
}

.boxTower {
  row-gap: 32px;
  display: grid;
}

.dim {
  opacity: .5;
}

.imageLink:hover {
  filter: brightness(.9);
}

@media screen and (width >= 768px) {
  article {
    flex: 3;
    width: calc(67dvw - 72px);
    max-width: 960px;
    padding: 64px;
  }

  .articleImage__mobile, .desktopHidden {
    display: none;
  }

  aside {
    flex: 1;
    row-gap: 32px;
    max-width: 640px;
    margin: 32px 0;
    display: grid;
    overflow: hidden;
  }

  h2 {
    max-width: 80%;
  }

  .box {
    gap: 48px 0;
    height: 640px;
    padding: 0 32px 0 64px;
  }
}

@media screen and (width >= 1608px) {
  .articleImage {
    border-radius: 12px;
  }
}



/*# sourceMappingURL=index.0e1a7fb5.css.map */
